.footer {
    background-color: #2f2f2f;
    padding-top: 40px;
    padding-bottom: 20px;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
}

.footer-text {
    color: #a8a8a8;
    align-self: center;
    margin: 5px 0px 5px 0px;
    font-family: 'Open Sans', sans-serif;
}

.footer-link-gov {
    align-self: center;
    font-size: 11px;
    text-decoration: none;
    margin-top: 20px;
}

.footer-link-gov:link {
    color: #777777;
    
}

.footer-link-gov:visited {
    color: #777777;
}