@font-face{
    font-family: 'OpenSans-Bold';
    src : url('/fonts/OpenSans-Bold-webfont.ttf');
}

@font-face{
    font-family: 'OpenSans-Regular';
    src : url('/fonts/OpenSans-Regular-webfont.ttf');
}

.project-header {
    background-color: #2f2f2f;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 20px;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    font-family: 'OpenSans-Bold';
    color: white;
    font-size: 50px;
}

.project-card-title {
    font-family: 'OpenSans-Bold';
    font-size: 30px;
}

.project-card-list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.project-card {
    padding-top: 10px;
    padding-bottom: 10px;
    max-width: 40%;
}

.project-image-wrapper {
    padding-top: 10px;
    display: flex;
    align-items: center;
    position: relative;
    
    
} 


.project-image {
    width: 100%;
    
    border-radius: 30px;
    
}


.project-image-mask {
    background-image: linear-gradient(rgba(255,255,255,0), 80%, rgba(255,255,255,1));
    position: absolute;
    width: 100%;
    height: 100%;
}

.project-detail {
    padding: 0px 10px;
    line-height: 120%;
    white-space: pre-line;
    font-family: 'OpenSans-Regular';
}

@media (max-width: 961px) {
    .project-header {
        font-size: 40px;
    }

    .project-card {
        max-width: 80%;
    }
}