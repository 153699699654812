@font-face{
    font-family: 'OpenSans-Bold';
    src : url('/fonts/OpenSans-Bold-webfont.ttf');
}

@font-face{
    font-family: 'OpenSans-Regular';
    src : url('/fonts/OpenSans-Regular-webfont.ttf');
}

.navbar {
    position: fixed;
    top: 0;
    background-color: transparent;
    width: 100%;
    display: flex;
    justify-content: center;
    transition: 0.5s;
    z-index: 100;
}

.navbar-switch-language {
    color: white;
    position: fixed;
    right: 10px;
    padding: 15px 0px;
    font-size: 14px;
    letter-spacing: 1.5px;
    cursor: pointer;
    user-select: none;
    font-family: 'OpenSans-Regular';
}

.navbar-switch-language:hover {
    color: #a8a8a8;
}

.navbar-button {
    color: white;
    font-size: 14px;
    font-family: 'OpenSans-Bold';
    letter-spacing: 3px;
    padding: 15px 30px;
    cursor: pointer;
}

.navbar-button:hover {
    color: #a8a8a8;
}

.navbar-button-highlight {
    color: #abdcff;
    font-size: 14px;
    font-family: 'OpenSans-Bold';
    letter-spacing: 3px;
    padding: 15px 30px;
    cursor: pointer;
}

@media (max-width: 961px) {
    .navbar-button {
        display: none;
    }
}