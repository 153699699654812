@font-face{
    font-family: 'OpenSans-Bold';
    src : url('/fonts/OpenSans-Bold-webfont.ttf');
}

@font-face{
    font-family: 'OpenSans-Regular';
    src : url('/fonts/OpenSans-Regular-webfont.ttf');
}

.cover {
    position: relative;
    width: 100%;
    height: 100%;
    background: #161415 url(/static/media/cover-bg.2101c161.jpg) no-repeat top center;
    background-size: cover;
    overflow: hidden;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.cover-main {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    flex: 8 1;
}

.cover-next {
    flex: 1 1;
}


.title {
    font-size: 6rem;
    color: white;
    text-align: center;
    font-family: 'OpenSans-Bold';
    margin-bottom: 40px;
    letter-spacing: 5px;
}


.intro {
    color: #a8a8a8;
    text-align: center;
    font-size: 15px;
    word-wrap: break-word;
    line-height: 150%;
    letter-spacing: 2px;
    width: 30%;
    font-family: 'OpenSans-Regular';
}

@media (max-width: 961px) {
    .title {
        font-size: 3rem;
    }
    .intro {
        width: 90%;
    }
}

.divider {
    width: 40%;
    border: solid #e3e3e3;
    border-width: 1px 0 0;
    border-color: rgba(150, 150, 150, .1);
    margin-top: 20px;
}

.cover-icon-list {
    margin-top: 20px;
    width: 500px;
    display: flex;
    justify-content: space-between;
}

@media (max-width: 961px) {
    .cover-icon-list {
        display: none;
    }
}


@font-face{
    font-family: 'OpenSans-Bold';
    src : url('/fonts/OpenSans-Bold-webfont.ttf');
}

@font-face{
    font-family: 'OpenSans-Regular';
    src : url('/fonts/OpenSans-Regular-webfont.ttf');
}

.about-bg {
    padding-top: 50px;
    padding-bottom: 80px;
    background-color: #2b2b2b;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
}

.about {
    width: 40%;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    margin: 0 auto;
}

@media (max-width: 961px) {
    .about {
        width: 90%;
    }
}

.about-title {
    color: white;
    font-family: 'OpenSans-Bold';
}

.about-detail {
    padding: 0;
    margin: 0;
    color: #9a9a9a;
    line-height: 180%;
    white-space: pre-line;
}


.resume-btn {
    background-color: #444444;
    color: white;
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 20px 25px;
    margin-top: 30px;
    align-self: center;
    cursor: pointer;
    border-radius: 5px;
    -webkit-user-select: none;
            user-select: none;
    font-size: 26px;
    transition: 0.4s;
}

.resume-btn:hover {
    background-color: white;
    color: black;
}

.resume-btn-text {
    margin-left: 15px;
    font-size: 18px;
    font-family: 'OpenSans-Bold';
}
@font-face{
    font-family: 'OpenSans-Bold';
    src : url('/fonts/OpenSans-Bold-webfont.ttf');
}

@font-face{
    font-family: 'OpenSans-Regular';
    src : url('/fonts/OpenSans-Regular-webfont.ttf');
}

.timeline-graph {
    width: 90%;
    margin: 0 auto;
    overflow: auto;
}

.taskText {
    font-size: 16px;
}

.resume {
    padding: 10px 0px 10px 0px;
}

.resume-label {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 24px;
    font-family: OpenSans-Bold;
    border-bottom: 3px solid #5D9CEC;
    padding: 4px 0px;
}

.resume-description {
    width: 100%;
    padding: 10px 0px;
    margin: 0;
}

.resume-title {
    color: black;
    font-size: 25px;
    margin: 0;
    padding: 0px;
    font-family: OpenSans-Regular;
}

.resume-info {
    color: #6E7881;
    margin: 10px 0px 10px 0px;
    font-size: 16px;
}

.resume-info-detail {
    color: #444444;
    font-size: 16px;
    white-space: pre-line;
}

.resume-info-image-container {
    height: 50px;
    width: 100%;
    margin-top: 10px;
}

.resume-info-image {
    height: 100%;
    object-fit: contain;
}

.resume-row-item {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    justify-content: center;
}

.resume-row-item-left {
    padding-top: 10px;
    width: 150px;
}

.resume-row-item-right {
    padding-left: 100px;
    width: 35%;
}

.resume-row-item-divider {
    border: 1px solid #E8E8E8;
    border-width: 1px 0 0;
    width: 50%;
}

.more-btn {
    background-color: #444444;
    color: white;
    font-family: OpenSans-Regular;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px 15px;
    margin-top: 10px;
    margin-bottom: 6px;
    cursor: pointer;
    border-radius: 5px;
    -webkit-user-select: none;
            user-select: none;
    font-size: 16px;
    transition: 0.4s;
}

.more-btn:hover {
    color: #a8a8a8;
}

.bottom-button-list {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    justify-content: center;
}

.visitor-btn {
    color: white;
    font-family: OpenSans-Regular;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 15px 25px;
    cursor: pointer;
    border-radius: 5px;
    -webkit-user-select: none;
            user-select: none;
    font-size: 16px;
    transition: 0.4s;
    margin: 0 10px 0 10px;
}

.visitor-btn:hover {
    color: #4D5061;
}

@media (max-width: 961px) {
    .resume-row-item {
        flex-direction: column;
    }
    .resume-row-item-left {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        align-self: center;
    }
    .resume-row-item-right {
        align-self: center;
        padding: 10px 0px;
        width: 80%;
        white-space: pre-line;
    }
}


.timesheet.white {
    border-top: 1px solid rgba(60, 60, 60, 0.0);
    /* background-color: #fbfbfb; */
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;

}

.timesheet.white .scale {
    height: 100%;
    position: absolute;
    width: 80%;
    /* top: 0;
    left: 0; 
    float: left; */
    display: flex;
    justify-content: center;
    
}

.indicator {
    position: absolute;
    top: 0;
    left: 0px;
    width: 1px;
    height: 100%;
    border-left: rgba(255, 0, 0, 0.4) solid 2px;
}

.timesheet.white .scale section {
    float: left;
    /* width: 100px; */
    /* default: 59 */
    flex: 1 1 auto;
    text-align: center;
    color: rgba(50, 50, 50, 0.8);
    font-family: "Signika Negative";
    font-size: 13px;
    line-height: 24px;
    font-weight: 300;
    border-left: 1px dashed rgba(50, 50, 50, 0.1);
}

.timesheet.white .data {
    margin: 28px 0 0 0;
    padding: 0;
    text-align: left;
    list-style-type: none;
    color: rgba(250, 250, 250, 0.8);
    font-family: "Signika Negative";
    font-size: 13px;
    overflow: hidden;
    width: 80%;

}

.timesheet.white .data li {
    margin: 0 0 3px 0;
    line-height: 22px;
    height: 21px;
    display: block;
    cursor: pointer;
    clear: both;
    position: relative;
    white-space: nowrap;
}

.timesheet.white .data li:hover .bubble {
    opacity: 1;
}

.timesheet.white .data li .date {
    color: #797979;
    font-size: 14px;
}

.timesheet.white .data li .info-left {
    position: absolute;
}

.timesheet.white .data li .label {
    font-weight: lighter;
    font-size: 14px;
    padding-left: 5px;
    line-height: 21px;
    color: #333332;
    white-space: nowrap;
    padding-right: 3px;
}

.timesheet.white .data li .bubble {
    width: 24px;
    height: 7px;
    display: block;
    float: left;
    position: relative;
    top: 7px;
    border-radius: 4px;
    margin: 0 10px 0 0;
    opacity: 0.7;
}

.timesheet.white .data li .bubble-default {
    background-color: #fc464a;
}

.timesheet.white .data li .bubble-research {
    background-color: #fc464a;
}

.timesheet.white .data li .bubble-edu {
    background-color: #9aca27;
}

.timesheet.white .data li .bubble-work {
    background-color: #3cb6e3;
}

.timesheet.white .data li .bubble-activity {
    background-color: #f4cf30;
}

.timesheet.white .data li .bubble-sit {
    background-color: #a969ca;
}
.footer {
    background-color: #2f2f2f;
    padding-top: 40px;
    padding-bottom: 20px;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
}

.footer-text {
    color: #a8a8a8;
    align-self: center;
    margin: 5px 0px 5px 0px;
    font-family: 'Open Sans', sans-serif;
}

.footer-link-gov {
    align-self: center;
    font-size: 11px;
    text-decoration: none;
    margin-top: 20px;
}

.footer-link-gov:link {
    color: #777777;
    
}

.footer-link-gov:visited {
    color: #777777;
}
@font-face{
    font-family: 'OpenSans-Bold';
    src : url('/fonts/OpenSans-Bold-webfont.ttf');
}

@font-face{
    font-family: 'OpenSans-Regular';
    src : url('/fonts/OpenSans-Regular-webfont.ttf');
}

.navbar {
    position: fixed;
    top: 0;
    background-color: transparent;
    width: 100%;
    display: flex;
    justify-content: center;
    transition: 0.5s;
    z-index: 100;
}

.navbar-switch-language {
    color: white;
    position: fixed;
    right: 10px;
    padding: 15px 0px;
    font-size: 14px;
    letter-spacing: 1.5px;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
    font-family: 'OpenSans-Regular';
}

.navbar-switch-language:hover {
    color: #a8a8a8;
}

.navbar-button {
    color: white;
    font-size: 14px;
    font-family: 'OpenSans-Bold';
    letter-spacing: 3px;
    padding: 15px 30px;
    cursor: pointer;
}

.navbar-button:hover {
    color: #a8a8a8;
}

.navbar-button-highlight {
    color: #abdcff;
    font-size: 14px;
    font-family: 'OpenSans-Bold';
    letter-spacing: 3px;
    padding: 15px 30px;
    cursor: pointer;
}

@media (max-width: 961px) {
    .navbar-button {
        display: none;
    }
}
.root {
    height: 100%;
}
@font-face{
    font-family: 'OpenSans-Bold';
    src : url('/fonts/OpenSans-Bold-webfont.ttf');
}

@font-face{
    font-family: 'OpenSans-Regular';
    src : url('/fonts/OpenSans-Regular-webfont.ttf');
}

.project-header {
    background-color: #2f2f2f;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 20px;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    font-family: 'OpenSans-Bold';
    color: white;
    font-size: 50px;
}

.project-card-title {
    font-family: 'OpenSans-Bold';
    font-size: 30px;
}

.project-card-list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.project-card {
    padding-top: 10px;
    padding-bottom: 10px;
    max-width: 40%;
}

.project-image-wrapper {
    padding-top: 10px;
    display: flex;
    align-items: center;
    position: relative;
    
    
} 


.project-image {
    width: 100%;
    
    border-radius: 30px;
    
}


.project-image-mask {
    background-image: linear-gradient(rgba(255,255,255,0), 80%, rgba(255,255,255,1));
    position: absolute;
    width: 100%;
    height: 100%;
}

.project-detail {
    padding: 0px 10px;
    line-height: 120%;
    white-space: pre-line;
    font-family: 'OpenSans-Regular';
}

@media (max-width: 961px) {
    .project-header {
        font-size: 40px;
    }

    .project-card {
        max-width: 80%;
    }
}
.contactPage {

}

.nameBar {
    position: fixed;
    top: 0;
    background-color: #FFFFFF60;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.nameBarItem {
    transform: rotate(-5deg);
    position: absolute;
    margin-top: 110px;
    font-size: 22px;
    color: white;
    background-color: #F55555A0;
}

.nameBarImage {
    margin-top: 16px;
    margin-bottom: 16px;
    width: 120px;
    height: 120px;
    border-radius: 50%;
}

.contactList {
    position: fixed;
    top: 152px;
    left: 10px;
    right: 10px;
    bottom: 0;
    overflow-y: scroll;
    display: grid;
    justify-content: space-around;
    align-content: start;
    grid-template-columns: repeat(auto-fill, 80px);
    grid-row-gap: 0px;
    grid-column-gap: 10px;
}

/* 单条联系信息 */
.contactItem {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contactItemIcon {
    width: 80px;
    height: 80px;
}

.contactItemText {
    margin-top: 2px;
    font-size: 14px;
    color: #000000A0;
}


.map-div {
    width: 100%;
    height: 100%;
}

.map-container {
    width: 100%;
    height: 100%;
}

.mapboxgl-canvas {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.marker {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.legend {
    width: 70px;
    height: 70px;
    position: fixed;
    bottom: 0px;
    right: 0px;
}

.legend-item {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: rgba(255, 255, 255, 0.3);
    font-size: 14px;
}
.contribution-graph {
    width: 100%;
    height: 100%;
    display: block;
}
