@font-face{
    font-family: 'OpenSans-Bold';
    src : url('/fonts/OpenSans-Bold-webfont.ttf');
}

@font-face{
    font-family: 'OpenSans-Regular';
    src : url('/fonts/OpenSans-Regular-webfont.ttf');
}

.timeline-graph {
    width: 90%;
    margin: 0 auto;
    overflow: auto;
}

.taskText {
    font-size: 16px;
}

.resume {
    padding: 10px 0px 10px 0px;
}

.resume-label {
    width: fit-content;
    font-size: 24px;
    font-family: OpenSans-Bold;
    border-bottom: 3px solid #5D9CEC;
    padding: 4px 0px;
}

.resume-description {
    width: 100%;
    padding: 10px 0px;
    margin: 0;
}

.resume-title {
    color: black;
    font-size: 25px;
    margin: 0;
    padding: 0px;
    font-family: OpenSans-Regular;
}

.resume-info {
    color: #6E7881;
    margin: 10px 0px 10px 0px;
    font-size: 16px;
}

.resume-info-detail {
    color: #444444;
    font-size: 16px;
    white-space: pre-line;
}

.resume-info-image-container {
    height: 50px;
    width: 100%;
    margin-top: 10px;
}

.resume-info-image {
    height: 100%;
    object-fit: contain;
}

.resume-row-item {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    justify-content: center;
}

.resume-row-item-left {
    padding-top: 10px;
    width: 150px;
}

.resume-row-item-right {
    padding-left: 100px;
    width: 35%;
}

.resume-row-item-divider {
    border: 1px solid #E8E8E8;
    border-width: 1px 0 0;
    width: 50%;
}

.more-btn {
    background-color: #444444;
    color: white;
    font-family: OpenSans-Regular;
    width: fit-content;
    padding: 10px 15px;
    margin-top: 10px;
    margin-bottom: 6px;
    cursor: pointer;
    border-radius: 5px;
    user-select: none;
    font-size: 16px;
    transition: 0.4s;
}

.more-btn:hover {
    color: #a8a8a8;
}

.bottom-button-list {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    justify-content: center;
}

.visitor-btn {
    color: white;
    font-family: OpenSans-Regular;
    width: fit-content;
    padding: 15px 25px;
    cursor: pointer;
    border-radius: 5px;
    user-select: none;
    font-size: 16px;
    transition: 0.4s;
    margin: 0 10px 0 10px;
}

.visitor-btn:hover {
    color: #4D5061;
}

@media (max-width: 961px) {
    .resume-row-item {
        flex-direction: column;
    }
    .resume-row-item-left {
        width: fit-content;
        align-self: center;
    }
    .resume-row-item-right {
        align-self: center;
        padding: 10px 0px;
        width: 80%;
        white-space: pre-line;
    }
}

