.map-div {
    width: 100%;
    height: 100%;
}

.map-container {
    width: 100%;
    height: 100%;
}

.mapboxgl-canvas {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.marker {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.legend {
    width: 70px;
    height: 70px;
    position: fixed;
    bottom: 0px;
    right: 0px;
}

.legend-item {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: rgba(255, 255, 255, 0.3);
    font-size: 14px;
}